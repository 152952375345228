import type { FC } from 'react';
import React, { useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useCheckpointContext } from '../../CheckpointContextProvider';
import { useEvent } from '../../../../hooks/useEvent';

const CheckpointDescriptionInputFC: FC = () => {
    const {
        checkpoint: { description },
        updateCheckpoint,
    } = useCheckpointContext();

    const ref = useRef<HTMLTextAreaElement>(null);
    const [value, setValue] = useState(description);

    const handleChange = useEvent((event: React.FormEvent<HTMLTextAreaElement>) => {
        setValue(event.currentTarget.value);
    });

    const handleBlur = useEvent(() => {
        updateCheckpoint({ description: value });
    });

    return (
        <TextareaAutosize
            value={value}
            className="block w-full outline-0 text-gray italic pl-2 resize-none"
            placeholder="Добавьте заметку..."
            onChange={handleChange}
            onBlur={handleBlur}
            ref={ref}
        />
    );
};

export const CheckpointDescriptionInput = React.memo(CheckpointDescriptionInputFC);
