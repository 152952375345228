import React, { FC, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { YMapListener } from 'ymap3-components';
import { MapEventUpdateHandler } from '@yandex/ymaps3-types/imperative/YMapListener';
import { useMutation } from '@tanstack/react-query';
import { useEvent } from '../../../hooks/useEvent';
import { useMap } from '../../../context/MapContext';
import { CheckpointSheet } from '../../CheckpointSheet/CheckpointSheet';
import { TgPoint } from '../TgPoint/TgPoint';
import { useCheckpointContext } from '../../CheckpointSheet/CheckpointContextProvider';
import { Coords } from '../../../types/types';
import { postCheckPointDelete } from '../../../queries/postCheckPointDelete';
import { useCheckPoints } from '../../../queries/getCheckPoints';
import avatar from './avatar_temp.jpg';

const SavedPointFC: FC = () => {
    const { checkpoint, updateCheckpoint } = useCheckpointContext();
    const map = useMap();

    console.log('feature', checkpoint);

    const [sheetOpened, setSheetOpened] = useState(false);
    const [coords, setCoords] = useState<Coords>([checkpoint.lon, checkpoint.lat]);
    const [isMoving, setIsMoving] = useState(false);

    const imageUrl = useMemo(
        () =>
            // TODO replace to undefined
            checkpoint.images[0]?.small ? URL.createObjectURL(checkpoint.images[0]!.small) : avatar,
        [checkpoint]
    );

    const handleClick = useEvent(() => {
        console.log('click on point', checkpoint.id);

        if (isMoving) {
            setIsMoving(false);
            updateCheckpoint({ lon: coords[0], lat: coords[1] });

            return;
        }

        map.setLocation({ center: coords, duration: 300 });

        setTimeout(() => setSheetOpened(true), 300);
    });

    const handleSheetClose = useEvent(() => {
        setSheetOpened(false);
        setCoords([checkpoint.lon, checkpoint.lat]);
    });
    const handleSave = useCallback(() => toast.success('Изменения сохранены', { autoClose: 1500 }), []);
    const handleReplace = useCallback(() => setIsMoving(true), []);

    const { mutateAsync: removeCheckPoint } = useMutation({
        mutationFn: postCheckPointDelete,
    });
    const { refetch: updateCheckpoints } = useCheckPoints();
    const handleRemove = useEvent(() => {
        setSheetOpened(false);

        setTimeout(
            () =>
                removeCheckPoint(checkpoint.id!)
                    .then(() => updateCheckpoints())
                    .then(() => toast.success('Метка удалена', { autoClose: 1500 })),
            300
        );
    });

    const handleUpdate = useCallback<MapEventUpdateHandler>(
        ({ location: { center } }) => requestAnimationFrame(() => setCoords([center[0], center[1]])),
        []
    );

    return (
        <>
            <TgPoint color={checkpoint.color} image={imageUrl} coords={coords} onClick={handleClick} />
            {isMoving && <YMapListener onUpdate={handleUpdate} />}
            <CheckpointSheet
                open={!isMoving && sheetOpened}
                onClose={handleSheetClose}
                onSave={handleSave}
                onReplace={handleReplace}
                onRemove={handleRemove}
            />
        </>
    );
};

export const SavedPoint = React.memo(SavedPointFC);
