import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { YMapClusterer } from 'ymap3-components';
import { Feature, IClusterMethod } from '@yandex/ymaps3-types/packages/clusterer/YMapClusterer/interface';
import { LngLat } from '@yandex/ymaps3-types/common/types';
import { Point } from '../../types/types';

type Props = {
    points: Point[];
    Marker: React.FC<{ point: Point }>;
    Cluster: React.FC<{ coordinates: LngLat; points: Point[] }>;
};

export const Clusterer: FC<Props> = ({ points, Marker, Cluster }) => {
    const [method, setMethod] = useState<IClusterMethod>();

    useEffect(() => {
        ymaps3
            .import('@yandex/ymaps3-clusterer@0.0.1')
            .then((module) => setMethod(module.clusterByGrid({ gridSize: 128 })));
    }, []);

    if (!method) {
        return null;
    }

    return (
        <YMapClusterer
            features={points as unknown as Feature[]}
            method={method}
            marker={(feature: Feature) => <Marker point={feature as unknown as Point} />}
            cluster={(coordinates: LngLat, features: Feature[]) => (
                <Cluster coordinates={coordinates} points={features as unknown as Point[]} />
            )}
        />
    );
};
