// https://ru.stackoverflow.com/questions/1521744/yandex-map-3-0-zoom-%D0%BF%D1%80%D0%B8-%D0%BA%D0%BB%D0%B8%D0%BA%D0%B5-%D0%BD%D0%B0-%D0%BA%D0%BB%D0%B0%D1%81%D1%82%D0%B5%D1%80
// eslint-disable-next-line import/no-unresolved
import { YMap } from '@yandex/ymaps3-types';
// eslint-disable-next-line import/no-unresolved
import { LngLat, LngLatBounds } from '@yandex/ymaps3-types/common/types';

const calculateWorldSize = (zoom: number) => Math.pow(2, zoom + 8);

const restrict = (value: number, min: number, max: number) => Math.max(Math.min(value, max), min);

const cycleRestrict = (value: number, min: number, max: number) => {
    if (value == Number.POSITIVE_INFINITY) {
        return max;
    } else if (value == Number.NEGATIVE_INFINITY) {
        return min;
    }
    return value - Math.floor((value - min) / (max - min)) * (max - min);
};

const latitudeToY = (lat: number) => {
    const radius = 6378137;
    const e = 0.0818191908426;
    const c_pi180 = Math.PI / 180;
    const epsilon = 1e-10;
    // epsilon чтобы не получить (-)Infinity
    const latitude = restrict(lat, -90 + epsilon, 90 - epsilon) * c_pi180;
    const esinLat = e * Math.sin(latitude);

    // Для широты -90 получается 0, и в результате по широте выходит -Infinity
    const tan_temp = Math.tan(Math.PI * 0.25 + latitude * 0.5);
    const pow_temp = Math.pow(Math.tan(Math.PI * 0.25 + Math.asin(esinLat) * 0.5), e);
    const U = tan_temp / pow_temp;

    return radius * Math.log(U);
};

const longitudeToX = (lng: number) => {
    const radius = 6378137;
    const c_pi180 = Math.PI / 180;
    const longitude = cycleRestrict(lng * c_pi180, -Math.PI, Math.PI);
    return radius * longitude;
};

const geoToMercator = (geo: LngLat) => [longitudeToX(geo[0]), latitudeToY(geo[1])];

const toGlobalPixels = (point: LngLat, zoom: number) => {
    const radius = 6378137;
    const equator = 2 * Math.PI * radius;
    const subequator = 1 / equator;
    let pixelsPerMeter = 256 * subequator;
    const halfEquator = equator / 2;
    let currentZoom = 0;

    if (zoom != currentZoom) {
        pixelsPerMeter = Math.pow(2, zoom + 8) * subequator;
        currentZoom = zoom;
    }

    const mercatorCoords = geoToMercator(point);
    return [(halfEquator + mercatorCoords[0]) * pixelsPerMeter, (halfEquator - mercatorCoords[1]) * pixelsPerMeter];
};

const toGlobalPixelBounds = (geoBounds: LngLatBounds, zoom = 0) => {
    const lowerCorner = toGlobalPixels(geoBounds[0], zoom);
    const upperCorner = toGlobalPixels(geoBounds[1], zoom);
    const projectionCycled = [true, false];
    const worldSize = calculateWorldSize(zoom);
    const result = [lowerCorner.slice(), upperCorner.slice()];
    if (lowerCorner[0] > upperCorner[0]) {
        if (projectionCycled[0]) {
            result[0][0] = lowerCorner[0];
            result[1][0] = upperCorner[0] + worldSize;
        } else {
            result[0][0] = upperCorner[0];
            result[1][0] = lowerCorner[0];
        }
    }
    if (lowerCorner[1] > upperCorner[1]) {
        if (projectionCycled[1]) {
            result[0][1] = lowerCorner[1];
            result[1][1] = upperCorner[1] + worldSize;
        } else {
            result[0][1] = upperCorner[1];
            result[1][1] = lowerCorner[1];
        }
    }
    return result;
};

export const getScale = (bounds: LngLatBounds, map: YMap, inscribe = true, floor = false) => {
    const pixelBounds = toGlobalPixelBounds(bounds, 0);
    // 1e-10 чтобы не было деления на 0
    const deltaX = Math.max(Math.abs(pixelBounds[1][0] - pixelBounds[0][0]), 1e-10);
    const deltaY = Math.max(Math.abs(pixelBounds[1][1] - pixelBounds[0][1]), 1e-10);
    const logX = Math.log(map.size.x / deltaX) * Math.LOG2E;
    const logY = Math.log(map.size.y / deltaY) * Math.LOG2E;
    const result = Math.min(Math.max(0, inscribe ? Math.min(logX, logY) : Math.max(logX, logY)), map.zoomRange.max);
    return floor ? Math.floor(result + 1e-10) : result - 1;
};
