import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useCheckpointContext } from '../../CheckpointContextProvider';
import styles from './CheckpointDescriptionBlock.module.css';

const COLLAPSED_HEIGHT = 72;
const LINE_HEIGHT = 24;

const CheckpointDescriptionBlockFC: FC = () => {
    const {
        checkpoint: { description },
    } = useCheckpointContext();

    const textRef = useRef<HTMLDivElement>(null);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [showCollapseBtn, setShowCollapseBtn] = useState(false);
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        if (!textRef.current) {
            return;
        }

        setMaxHeight(textRef.current.scrollHeight + LINE_HEIGHT);
        setShowCollapseBtn(textRef.current.scrollHeight > COLLAPSED_HEIGHT);
    }, []);

    return (
        <div className="relative overflow-hidden" onClick={() => setIsCollapsed((prev) => !prev)}>
            <div
                className="whitespace-pre-wrap text-gray italic pl-2 transition-all duration-300 ease-in-out"
                style={showCollapseBtn ? { height: isCollapsed ? COLLAPSED_HEIGHT : maxHeight } : undefined}
                ref={textRef}
            >
                {description}
            </div>
            {showCollapseBtn && (
                <div className="absolute bottom-0 right-0 flex">
                    {isCollapsed && <div className={styles.mask} />}
                    <span className="text-blue-500 bg-white pl-4">{isCollapsed ? 'развернуть' : 'свернуть'}</span>
                </div>
            )}
        </div>
    );
};

export const CheckpointDescriptionBlock = React.memo(CheckpointDescriptionBlockFC);
