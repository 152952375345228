import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import cx from 'classnames';
import styles from './Button.module.css';
import loader from '../../static/loader.svg';

type Props = {
    size?: 'm' | 'l';
    theme?: 'green' | 'orange' | 'red' | 'outline';
    onClick?: () => void;
    loading?: boolean;
    full?: boolean;
};

export const Button: FC<PropsWithChildren<Props>> = ({
    size = 'l',
    theme,
    onClick,
    loading,
    full = true,
    children,
}) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (loading) {
            return;
        }

        return onClick?.();
    };

    return (
        <button
            type="button"
            className={cx(
                'px-12 rounded-m flex justify-center duration-150 focus-visible:outline-none',
                styles.button,
                {
                    ['bg-green active:bg-green-dark']: theme === 'green',
                    ['bg-orange-400 active:bg-orange-500']: theme === 'orange',
                    ['bg-red active:bg-red-dark']: theme === 'red',
                    ['text-blue-500 active:text-blue-800']: theme === 'outline',
                    ['text-white font-medium']: theme !== 'outline',
                    ['py-16 text-l leading-6 h-56']: size === 'l',
                    ['py-8 text-m leading-5 h-36']: size === 'm',
                    ['w-full']: full,
                }
            )}
            onClick={handleClick}
        >
            {loading ? <img src={loader} width={24} /> : children}
        </button>
    );
};
