import { useCallback, useInsertionEffect, useRef } from 'react';

export const useEvent = <T extends (...args: any[]) => any>(callback: T): T => {
    const callbackRef = useRef<T>(callback);

    useInsertionEffect(() => {
        callbackRef.current = callback;
    });

    return useCallback(((...args) => callbackRef.current(...args)) as T, []);
};
