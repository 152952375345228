import type { FC } from 'react';
import React from 'react';
import { Button } from './Button';
import { BottomSheet } from './BottomSheet/BottomSheet';

type Props = {
    opened: boolean;
    onClose(): void;
    onClick(): void;
    desc?: React.ReactNode;
};

export const RemoveSheet: FC<Props> = ({
    opened,
    onClose,
    onClick,
    desc = 'Это действие нельзя отменить, все данные будут безвозратно удалены!',
}) => (
    <BottomSheet opened={opened} onClose={onClose} detent="content-height">
        <div className="px-16 mb-16">
            <div className="font-medium text-l mb-4">Уверены, что хотите удалить?</div>
            <div className="text-gray italic">{desc}</div>
            <br />
            <Button theme="red" size="l" full onClick={onClick}>
                Удалить
            </Button>
            <Button theme="outline" size="l" full onClick={onClose}>
                Отмена
            </Button>
        </div>
    </BottomSheet>
);
