import type { FC } from 'react';
import React, { useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { LngLat } from '@yandex/ymaps3-types/common/types';
import { YMapMarker } from 'ymap3-components';
import { Point } from '../../../types/types';
import { useEvent } from '../../../hooks/useEvent';
import { getScale } from '../../../utils/map/getScale';
import { useMap } from '../../../context/MapContext';
import { PointDonut } from '../../../utils/PointDonut';

type Props = {
    coordinates: LngLat;
    points: Point[];
};

const SavedClustererFC: FC<Props> = ({ coordinates, points }) => {
    const map = useMap();

    const imageUrl = useMemo(() => {
        const image = points.find(({ properties }) => properties.images.length > 0)?.properties.images[0]?.small;

        return image ? URL.createObjectURL(image) : undefined;
    }, [points]);

    const donutColors = useMemo(() => points.map((p) => p.properties.color), [points]);

    const { center, zoom } = useMemo<{ center: LngLat; zoom: number }>(() => {
        const lngMin = Math.min(...points.map((p) => p.geometry.coordinates[0]));
        const lngMax = Math.max(...points.map((p) => p.geometry.coordinates[0]));
        const latMin = Math.min(...points.map((p) => p.geometry.coordinates[1]));
        const latMax = Math.max(...points.map((p) => p.geometry.coordinates[1]));

        const bounds = [
            [lngMin, latMin],
            [lngMax, latMax],
        ] as const;

        const center = [
            bounds[0][0] + (bounds[1][0] - bounds[0][0]) / 2,
            bounds[0][1] + (bounds[1][1] - bounds[0][1]) / 2,
        ] as LngLat;

        console.log('true bounds', bounds);
        console.log('true center', center);

        const zoom = getScale(
            [
                [lngMin, latMin],
                [lngMax, latMax],
            ],
            map
        );

        console.log('will zoom', zoom);

        return { center, zoom };
    }, [points]);

    const handleClick = useEvent(() => map.setLocation({ center, duration: 300, zoom }));

    return (
        <YMapMarker coordinates={coordinates} onFastClick={handleClick}>
            <div
                className="w-point h-point bg-clip-border rounded-full overflow-hidden -translate-x-2/4 -translate-y-2/4"
                style={{ borderColor: 'black' }}
            >
                {imageUrl ? (
                    <img src={imageUrl} width="100%" height="100%" className="absolute object-cover -z-10" />
                ) : null}
                <div className="w-full h-full bg-black-60">
                    <span className="block text-white text-l text-center relative top-1/2 -translate-y-1/2">
                        {points.length}
                    </span>
                </div>
            </div>
            <div className="absolute -top-1/2 -left-1/2 w-full">
                <PointDonut colors={donutColors} />
            </div>
        </YMapMarker>
    );
};

export const SavedClusterer = React.memo(SavedClustererFC);
