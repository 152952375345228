import type { FC } from 'react';
import React from 'react';
import styles from './Skeleton.module.css';

type Props = {
    height: number;
    width?: number;
};

export const Skeleton: FC<Props> = ({ height, width = '100%' }) => (
    <div className={styles.block} style={{ height, width }}></div>
);
