import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useCheckpointContext } from '../CheckpointContextProvider';
import styles from './CheckpointLocation.module.css';

export const CheckpointLocation: FC = () => {
    const {
        checkpoint: { location },
        isEditing,
        updateCheckpoint,
    } = useCheckpointContext();

    const [editorShown, setEditorShown] = useState(false);

    useEffect(() => {
        if (isEditing) {
            setEditorShown(true);
        }
    }, [isEditing]);

    if (!editorShown) {
        return (
            <div
                className={cx(
                    'first-letter:uppercase ml-4 w-full text-black text-l leading-8 font-medium',
                    styles.title
                )}
                onClick={isEditing ? () => setEditorShown(true) : undefined}
            >
                {location}
            </div>
        );
    }

    return (
        <input
            value={location}
            className={cx(
                'first-letter:uppercase ml-4 w-full text-black text-l leading-8 font-medium disabled:bg-transparent disabled:opacity-100 focus:outline-none',
                styles.input
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                updateCheckpoint({ location: event.target.value })
            }
            onBlur={() => setEditorShown(false)}
        />
    );
};
