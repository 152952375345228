import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useCheckPoints } from '../../queries/getCheckPoints';
import { Point } from '../../types/types';
import { SavedClusterer } from '../MapElements/SavedClusterer/SavedClusterer';
import { SavedPointWrapper } from '../MapElements/SavedPoint/SavedPointWrapper';
import { Clusterer } from '../Base/Clusterer';

const CheckpointsClusterFC: FC = () => {
    const { data: checkpoints } = useCheckPoints();

    console.log('points', checkpoints);

    const points = useMemo<Point[] | undefined>(
        () =>
            checkpoints?.map((checkpoint) => ({
                type: 'Feature',
                id: checkpoint.id!.toString(),
                geometry: { type: 'Point', coordinates: [checkpoint.lon, checkpoint.lat] },
                properties: checkpoint,
            })),
        [checkpoints]
    );

    if (!points?.length) {
        return null;
    }

    return <Clusterer points={points} Marker={SavedPointWrapper} Cluster={SavedClusterer} />;
};

export const CheckpointsCluster = React.memo(CheckpointsClusterFC);
