import type { FC } from 'react';
import React from 'react';
import { useCheckpointContext } from '../../CheckpointContextProvider';
import { CheckpointDescriptionInput } from './CheckpointDescriptionInput';
import { CheckpointDescriptionBlock } from './CheckpointDescriptionBlock';

export const CheckpointDescription: FC = () => {
    const {
        checkpoint: { description },
        isEditing,
    } = useCheckpointContext();

    if (isEditing) {
        return <CheckpointDescriptionInput />;
    }

    if (!description) {
        return null;
    }

    return <CheckpointDescriptionBlock />;
};
