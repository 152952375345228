import React, { FC, useMemo } from 'react';
import { PlacemarkColor } from '../components/CheckpointSheet/components/CheckpointMarker';

const RADIUS = 25;
const PERIMETER = 2 * Math.PI * RADIUS;

type Props = {
    colors: PlacemarkColor[];
};

const PointDonutFC: FC<Props> = ({ colors }) => {
    const count = colors.length;
    const itemWidth = PERIMETER / count;

    const groupedColors = useMemo(
        () =>
            colors.reduce(
                ({ items, prevOffset }, color) => {
                    items.push({ color, count: 1, offset: prevOffset });

                    return { items, prevOffset: prevOffset - itemWidth };
                },
                { items: [] as Array<{ color: string; count: number; offset: number }>, prevOffset: 0 }
            ).items,
        [colors, itemWidth]
    );

    return (
        <svg viewBox="0 0 54 54">
            <g strokeWidth="4">
                {groupedColors.map(({ color, count, offset }, index, array) => (
                    <circle
                        cx="27"
                        cy="27"
                        r={RADIUS}
                        fill="transparent"
                        strokeDasharray={`${
                            index !== array.length - 1 ? itemWidth * count : itemWidth * count + 1
                        } ${PERIMETER}`}
                        strokeDashoffset={offset}
                        stroke={color}
                        key={color}
                    ></circle>
                ))}
            </g>
        </svg>
    );
};

export const PointDonut = React.memo(PointDonutFC);
