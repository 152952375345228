import Dexie, { Table } from 'dexie';
import { DateObject } from 'react-multi-date-picker';
import { PlacemarkColor } from './components/CheckpointSheet/components/CheckpointMarker';

export type PointImage = { id: string; origin: File; small?: File };

export type PointTable = {
    id?: number;
    lat: number;
    lon: number;
    color: PlacemarkColor;
    location: string;
    date: [number] | [number, number];
    images: Array<PointImage>;
    description?: string;
};

export type Checkpoint = Omit<PointTable, 'date'> & { date: [DateObject] | [DateObject, DateObject] };

export class MySubClassedDexie extends Dexie {
    // 'friends' is added by dexie when declaring the stores()
    // We just tell the typing system this is the case
    checkpoints!: Table<PointTable>;

    constructor() {
        super('fpoint_db');
        this.version(1).stores({
            checkpoints: '++id, lat, lon, color, location, date, images, description',
        });
    }
}

export const db = new MySubClassedDexie();
