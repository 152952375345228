import { createContext, useContext } from 'react';
import * as YMaps from '@yandex/ymaps3-types';

export const MapContext = createContext<YMaps.YMap | undefined>(undefined);

export const useMap = () => {
    const map = useContext(MapContext);

    if (!map) {
        throw new Error('no map found');
    }

    return map;
};
