import type { FC } from 'react';
import React from 'react';
import { YMapMarker } from 'ymap3-components';
import { LngLat } from '@yandex/ymaps3-types';
import { PointSvg } from '../SavedPoint/PointSvg';
import { PlacemarkColor } from '../../CheckpointSheet/components/CheckpointMarker';

type Props = {
    color: PlacemarkColor;
    image?: string;
    coords: LngLat;
    onClick?: () => void;
};

export const TgPoint: FC<Props> = ({ color, image, coords, onClick }) => (
    <YMapMarker coordinates={coords}>
        <div className="relative w-point h-point -translate-x-2/4 -translate-y-full" onClick={onClick}>
            <div className="-z-10 bg-white absolute left-0 right-0 ml-auto mr-auto w-2/3 h-2/3 rounded-full overflow-hidden">
                {image ? <img src={image} width="100%" height="100%" className="object-cover" /> : null}
            </div>
            <PointSvg fill={color} />
        </div>
    </YMapMarker>
);
