import type { FC } from 'react';
import React from 'react';
import { PlacemarkColor } from './CheckpointMarker';

type Props = {
    color: PlacemarkColor;
};

export const MarkerSVG: FC<Props> = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="-4 0 36 36">
        <g fill="none" fillRule="evenodd">
            <path
                fill={color}
                d="M14 0c7.732 0 14 5.641 14 12.6C28 23.963 14 36 14 36S0 24.064 0 12.6C0 5.641 6.268 0 14 0Z"
            />
            <circle cx="14" cy="14" r="7" fill="#fff" fillRule="nonzero" />
        </g>
    </svg>
);
