import { Checkpoint, db } from '../db';

export const postNewCheckPointSave = async ({ id, date, ...other }: Checkpoint) => {
    const point = { ...other, date: date.map((d) => d.toJSON()) as [number] | [number, number] };

    if (id) {
        return db.checkpoints.update(id, point);
    }

    return db.checkpoints.add(point);
};
