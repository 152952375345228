import type { FC } from 'react';
import React, { useRef } from 'react';
import { Menu, TriggerEvent, useContextMenu } from 'react-contexify';
import { MarkerSVG } from './MarkerSVG';
import { useCheckpointContext } from '../CheckpointContextProvider';

const COLOR_PICKER_ID = 'COLOR_PICKER_ID';

export const PLACEMARK_COLORS = [
    '#4098FF',
    '#ED4543',
    '#E6761B',
    '#194679',
    '#317BC9',
    '#F371D1',
    '#B3B3B3',
    '#793D0E',
    '#2CAD04',
    '#B51EFF',
    '#595959',
    '#F9D21D',
    '#56DB40',
    '#F6931E',
    '#81CCFF',
    '#97A102',
] as const;

export type PlacemarkColor = (typeof PLACEMARK_COLORS)[number];

export const CheckpointMarker: FC = () => {
    const {
        checkpoint: { color },
        isEditing,
        updateCheckpoint,
    } = useCheckpointContext();
    const ref = useRef<HTMLImageElement>(null);

    const { show } = useContextMenu({
        id: COLOR_PICKER_ID,
    });

    const handlePlacemarkClick = (event: TriggerEvent) => {
        if (!ref.current || !isEditing) {
            return;
        }

        const y = ref.current.getBoundingClientRect().y + ref.current.getBoundingClientRect().height - 12;

        return show({
            event,
            position: {
                x: 16,
                y,
            },
        });
    };

    return (
        <>
            <div className="h-32 w-32" onClick={handlePlacemarkClick} ref={ref}>
                <MarkerSVG color={color} />
            </div>
            {isEditing && (
                <Menu id={COLOR_PICKER_ID} animation="scale">
                    <div className="grid grid-cols-4 gap-8 p-8">
                        {PLACEMARK_COLORS.map((hex) => (
                            <div
                                className="w-48 h-48 rounded-m"
                                style={{ background: hex }}
                                onClick={() => updateCheckpoint({ color: hex })}
                                key={hex}
                            />
                        ))}
                    </div>
                </Menu>
            )}
        </>
    );
};
