import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useEvent } from '../../../../hooks/useEvent';
import { PointImage } from '../../../../db';
import { ImageFullscreenCrop } from './ImageFullscreenCrop';
import { useCheckpointContext } from '../../CheckpointContextProvider';

type Props = {
    image: PointImage;
    onClick(index: number): void;
    index: number;
};

const PanelItemFC: FC<Props> = ({ image, onClick, index }) => {
    const { isEditing } = useCheckpointContext();

    const [src, setSrc] = useState<string>();
    const [cropperShow, setCropperShow] = useState(false);

    useEffect(() => {
        const img = new Image();

        if (!image.small) {
            return setSrc(undefined);
        }

        img.src = URL.createObjectURL(image.small);
        img.onload = () => {
            setSrc(img.src);
        };
        img.onerror = () => {
            setSrc(undefined);
        };
    }, [image.small]);

    const handleClick = () => {
        if (isEditing) {
            return setCropperShow(true);
        }

        return onClick(index);
    };

    const handleCloseCropper = useEvent(() => setCropperShow(false));

    return (
        <>
            <div className="relative basis-1/3 aspect-square rounded-m overflow-hidden">
                {src ? (
                    <img src={src} className="w-full h-full object-cover select-none" onClick={handleClick} />
                ) : (
                    <div className="w-full h-full object-cover select-none bg-light-gray"></div>
                )}
            </div>
            {cropperShow && <ImageFullscreenCrop image={image} onClose={handleCloseCropper} />}
        </>
    );
};

export const PanelItem = React.memo(PanelItemFC);
