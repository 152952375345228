import type { FC } from 'react';
import React from 'react';
import cx from 'classnames';
import Sheet from 'react-modal-sheet';
import styles from './BottomSheet.module.css';

type Props = {
    opened: boolean;
    onClose(): void;
    detent?: 'full-height' | 'content-height';
    header?: React.ReactNode;
    footer?: React.ReactNode;
    noBackdrop?: boolean;
};

export const BottomSheet: FC<React.PropsWithChildren<Props>> = ({
    opened,
    onClose,
    detent = 'full-height',
    header,
    footer,
    noBackdrop = false,
    children,
}) => (
    <Sheet isOpen={opened} onClose={onClose} detent={detent} snapPoints={[1, 0]} style={{ zIndex: 1000 }}>
        <Sheet.Container className={cx({ [styles.fullHeight]: detent === 'full-height' })}>
            <Sheet.Header style={{ height: 24 }} />
            <Sheet.Content>
                {header && <div className="px-16 pb-12 border-b border-solid border-light-gray">{header}</div>}
                <Sheet.Scroller>{children}</Sheet.Scroller>
                {footer && <div className="mb-40 px-16 pt-4 border-t border-solid border-light-gray">{footer}</div>}
            </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
            className={cx(styles.backdrop, { [styles.hidden]: noBackdrop })}
            onTap={!noBackdrop ? onClose : undefined}
        />
    </Sheet>
);
