import { createContext, useContext } from 'react';
import { Checkpoint } from '../../db';

export type CheckpointContext = {
    isEditing: boolean;
    checkpoint: Checkpoint;
    onChangeEdit(): void;
    onCancelEdit(): void;
    updateCheckpoint: (value: Partial<Checkpoint>) => void;
};

const CheckpointContext = createContext<CheckpointContext | undefined>(undefined);

export const useCheckpointContext = () => {
    const context = useContext(CheckpointContext);

    if (!context) {
        throw new Error('Checkpoint compound components cannot be rendered outside of the main Checkpoint component');
    }

    return context;
};

export const CheckpointContextProvider = CheckpointContext.Provider;
