import React, { FC } from 'react';
import { PlacemarkColor } from '../../CheckpointSheet/components/CheckpointMarker';

type Props = {
    fill: PlacemarkColor;
};

export const PointSvg: FC<Props> = ({ fill }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        viewBox="0 0 740 1080"
        xmlSpace="preserve"
    >
        <g transform="matrix(8.56 0 0 8.56 368.07 380.57)">
            <path
                fill={fill}
                stroke={fill}
                strokeWidth={2}
                vectorEffect="non-scaling-stroke"
                transform=" translate(-50, -50)"
                d="M 50 92.875 C 26.358 92.875 7.125 73.642 7.125 50 C 7.125 26.358000000000004 26.358 7.125 50 7.125 C 73.642 7.125 92.875 26.358 92.875 50 C 92.875 73.642 73.642 92.875 50 92.875 z M 50 9.125 C 27.461 9.125 9.125 27.461 9.125 50 C 9.125 72.538 27.461 90.875 50 90.875 C 72.538 90.875 90.875 72.538 90.875 50 C 90.875 27.461 72.538 9.125 50 9.125 z"
                strokeLinecap="round"
            />
        </g>
        <g transform="matrix(-2.68 0 0 -1.98 369.97 798.01)" id="81753892-722b-4a7d-84d7-fa6e7145f768">
            <polygon
                fill={fill}
                stroke={fill}
                vectorEffect="non-scaling-stroke"
                points="-37.43,32.41 0,-32.41 37.43,32.41 "
            />
        </g>
        <g transform="matrix(1.09 0 0 1.09 370 1028.69)" id="1af13324-f9b6-4284-8a41-eb98cc8e311c">
            <circle fill={fill} stroke={fill} vectorEffect="non-scaling-stroke" cx="0" cy="0" r="35" />
        </g>
    </svg>
);
