import type { FC } from 'react';
import React, { useRef, useState } from 'react';
import { Item, Menu, TriggerEvent, useContextMenu } from 'react-contexify';
import { useEvent } from '../../../hooks/useEvent';
import { RemoveSheet } from '../../Base/RemoveSheet';
import { useCheckpointContext } from '../CheckpointContextProvider';
import placemark from '../static/placemark.png';
import remove from '../static/remove.png';
import menu from '../static/menu.png';

const CHECKPOINT_MENU_ID = 'CHECKPOINT_MENU_ID';
const MENU_WIDHT = 150;
const HEADER_PADDING = 16;

type Props = {
    onRemove?: () => void;
    onReplace?: () => void;
};

export const CheckpointEdit: FC<Props> = ({ onRemove, onReplace }) => {
    const {
        checkpoint: { location },
    } = useCheckpointContext();

    const ref = useRef<HTMLImageElement>(null);
    const [menuShow, setMenuShow] = useState(false);
    const [removeSheetShow, setRemoveSheetShow] = useState(false);

    const { show, hideAll } = useContextMenu({
        id: CHECKPOINT_MENU_ID,
    });

    const handleMenuClick = (event: TriggerEvent) => {
        if (!ref.current) {
            return;
        }

        if (menuShow) {
            hideAll();

            return setMenuShow(false);
        }

        const y = ref.current.getBoundingClientRect().y + ref.current.getBoundingClientRect().height - 12;

        show({
            event,
            position: {
                x: window.innerWidth - MENU_WIDHT - HEADER_PADDING,
                y,
            },
        });

        return setMenuShow(true);
    };

    const handleVisibilityChange = useEvent((isVisible: boolean) => {
        if (!isVisible) {
            return setMenuShow(false);
        }
    });

    const handleRemove = useEvent(() => {
        console.log('wanna remove checkpoint');

        setRemoveSheetShow(false);

        onRemove?.();
    });

    const handleReplace = useEvent(() => {
        console.log('wanna replace checkpoint');

        onReplace?.();
    });

    if (!onRemove && !onReplace) {
        return null;
    }

    return (
        <>
            <img src={menu} onClick={handleMenuClick} width={32} height={32} className="inline-block" ref={ref} />
            <Menu id={CHECKPOINT_MENU_ID} animation="scaleRight" onVisibilityChange={handleVisibilityChange}>
                {onRemove && (
                    <Item onClick={() => setRemoveSheetShow(true)}>
                        <img src={remove} width={24} height={24} />
                        <span className="ml-4">Удалить</span>
                    </Item>
                )}
                {onReplace && (
                    <Item onClick={handleReplace}>
                        <img src={placemark} width={24} height={24} />
                        <span className="ml-4">Переместить</span>
                    </Item>
                )}
            </Menu>
            <RemoveSheet
                opened={removeSheetShow}
                onClose={() => setRemoveSheetShow(false)}
                onClick={handleRemove}
                desc={
                    <>
                        Это действие нельзя отменить, все данные о поездке <strong>{location}</strong> будут безвозратно
                        удалены!
                    </>
                }
            />
        </>
    );
};
