import { DateObject } from 'react-multi-date-picker';

export const WEEK_DAYS = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
export const MONTHS = [
    ['Январь', 'января'],
    ['Февраль', 'февраля'],
    ['Март', 'марта'],
    ['Апрель', 'апреля'],
    ['Май', 'мая'],
    ['Июнь', 'июня'],
    ['Июль', 'июля'],
    ['Август', 'августа'],
    ['Сентябрь', 'сентября'],
    ['Октябрь', 'октября'],
    ['Ноябрь', 'ноября'],
    ['Декабрь', 'декабря'],
];

export const formatSelectedDate = (date: DateObject | DateObject[] | null) => {
    if (!date || (Array.isArray(date) && !date.length)) {
        return '';
    }

    if (!Array.isArray(date)) {
        return `${date.day} ${MONTHS[date.month.index]![1]} ${date.year}`;
    }

    if (date.length === 1) {
        return `${date[0]!.day} ${MONTHS[date[0]!.month.index]![1]} ${date[0]!.year}`;
    }

    const [start, end] = date as [DateObject, DateObject];

    if (start.day === end.day && start.month.number === end.month.number) {
        return `${start.day} ${MONTHS[start.month.index]![1]} ${start.year}`;
    }

    if (start.month.number === end.month.number) {
        return `${start.day} \u2013 ${end.day} ${MONTHS[start.month.index]![1]} ${start.year}`;
    }

    if (start.year !== end.year) {
        return `${start.format('DD.MM.YY')} \u2013 ${end.format('DD.MM.YY')}`;
    }

    return `${start.format('DD.MM')} \u2013 ${end.format('DD.MM')} ${start.year}`;
};
