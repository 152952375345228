import { useQuery } from '@tanstack/react-query';
import { DateObject } from 'react-multi-date-picker';
import { Checkpoint, db } from '../db';

export const useCheckPoints = () =>
    useQuery({
        queryKey: ['getCheckPoints'],
        queryFn: () =>
            // TODO: check subscription
            // TODO: request server

            db.checkpoints
                .toArray()
                .then((points) =>
                    points.map(
                        ({ date, ...point }) => ({ ...point, date: date.map((d) => new DateObject(d)) }) as Checkpoint
                    )
                ),
        staleTime: 5000,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
