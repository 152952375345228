import { FC } from 'react';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button } from '../Base/Button';
import { PhotoLoadPanel } from './components/PhotoLoadPanel/PhotoLoadPanel';
import { postNewCheckPointSave } from '../../queries/postNewCheckPointSave';
import { useEvent } from '../../hooks/useEvent';
import { CheckpointMarker } from './components/CheckpointMarker';
import { CheckpointDate } from './components/CheckpointDate';
import { useCheckPoints } from '../../queries/getCheckPoints';
import { useCheckpointContext } from './CheckpointContextProvider';
import { CheckpointLocation } from './components/CheckpointLocation';
import { CheckpointEdit } from './components/CheckpointEdit';
import { CheckpointEditBtn } from './components/CheckpointEditBtn';
import { CheckpointDescription } from './components/CheckpointDescription/CheckpointDescription';
import { BottomSheet } from '../Base/BottomSheet/BottomSheet';

type Props = {
    open: boolean;
    onClose(): void;
    onSave?: () => void;
    onReplace?: () => void;
    onRemove?: () => void;
};

const CheckpointSheetFC: FC<Props> = ({ open, onClose, onSave, onReplace, onRemove }) => {
    console.log('CheckpointSheet');
    const { checkpoint, isEditing, onChangeEdit, onCancelEdit } = useCheckpointContext();

    const { mutateAsync: saveCheckPoint } = useMutation({
        mutationFn: postNewCheckPointSave,
    });
    const { refetch: updateCheckpoints } = useCheckPoints();

    const [saveLoading, setSaveLoading] = useState(false);

    const handleSave = useEvent(() => {
        if (!open || !isEditing) {
            return Promise.resolve();
        }

        setSaveLoading(true);

        return saveCheckPoint(checkpoint)
            .then(() => updateCheckpoints())
            .then(() => {
                onChangeEdit();

                onSave?.();
            })
            .finally(() => {
                setSaveLoading(false);
            });
    });

    const handleClose = useEvent(() => {
        isEditing && onCancelEdit();

        return onClose();
    });

    return (
        <BottomSheet
            opened={open}
            onClose={handleClose}
            header={
                <div className="text-start">
                    <div className="flex mb-4 justify-between">
                        <div className="flex" style={{ width: 'calc(100% - 36px)' }}>
                            <CheckpointMarker />
                            <CheckpointLocation />
                        </div>
                        {!isEditing && <CheckpointEditBtn />}
                        {isEditing && <CheckpointEdit onReplace={onReplace} onRemove={onRemove} />}
                    </div>
                    <CheckpointDate />
                    <CheckpointDescription />
                </div>
            }
            footer={
                isEditing ? (
                    <Button theme="orange" onClick={handleSave} loading={saveLoading}>
                        Сохранить
                    </Button>
                ) : undefined
            }
        >
            <div className="p-4">
                <PhotoLoadPanel />
            </div>
        </BottomSheet>
    );
};

export const CheckpointSheet = React.memo(CheckpointSheetFC);
