import React, { useState, FC, useEffect } from 'react';
import {
    YMap,
    YMapComponentsProvider,
    YMapDefaultSchemeLayer,
    YMapDefaultFeaturesLayer,
    YMapControls,
    YMapZoomControl,
} from 'ymap3-components';
import { ToastContainer, Bounce } from 'react-toastify';
import * as YMaps from '@yandex/ymaps3-types';
import { EntryPlacemark } from './components/EntryPlacemark/EntryPlacemark';
import { CheckpointsCluster } from './components/CheckpointsCluster/CheckpointsCluster';
import { YANDEX_API_KEY } from './services/constants';
import 'react-contexify/ReactContexify.css';
import 'cropperjs/dist/cropper.css';
import './scss/main.scss';
import { MapContext } from './context/MapContext';

export const MOSCOW = { center: [37.57, 55.75], zoom: 4 };

export const MyMap: FC = () => (
    <>
        <CheckpointsCluster />
        <EntryPlacemark />
    </>
);

const App: FC = () => {
    const [ymap3, setYmap3] = useState<YMaps.YMap | null>(null);

    console.log('APP', ymap3);

    useEffect(() => document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#000000'), []);

    return (
        <>
            <div className="w-screen h-screen">
                <YMapComponentsProvider apiKey={YANDEX_API_KEY} lang="ru_RU">
                    <YMap key="map" ref={setYmap3} location={MOSCOW} mode="vector" theme="light">
                        <YMapDefaultSchemeLayer />
                        <YMapDefaultFeaturesLayer />
                        {ymap3 && (
                            <>
                                <MapContext.Provider value={ymap3}>
                                    <MyMap />
                                </MapContext.Provider>
                                <YMapControls position="right">
                                    <YMapZoomControl />
                                </YMapControls>
                            </>
                        )}
                    </YMap>
                </YMapComponentsProvider>
            </div>
            <ToastContainer
                position="top-right"
                transition={Bounce}
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                closeButton={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
                theme="colored"
            />
        </>
    );
};

export default App;
