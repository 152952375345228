import type { FC } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line import/named
import Cropper, { ReactCropperElement } from 'react-cropper';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { PointImage } from '../../../../db';
import { ModalOverlay } from '../../../Base/ModalOverlay';
import { Button } from '../../../Base/Button';
import { useEvent } from '../../../../hooks/useEvent';
import { useCheckpointContext } from '../../CheckpointContextProvider';

const imageProcessingWorker = createWorkerFactory(() => import('../resizer'));

type Props = {
    image: PointImage;
    onClose(): void;
};

export const ImageFullscreenCrop: FC<Props> = ({ image, onClose }) => {
    const {
        checkpoint: { images },
        updateCheckpoint,
    } = useCheckpointContext();

    const [imgEl, setImgEl] = useState<HTMLImageElement>();
    const cropperRef = useRef<ReactCropperElement>(null);

    const resizer = useWorker(imageProcessingWorker);

    useEffect(() => {
        const img = new Image();

        if (!image.small) {
            return;
        }

        img.src = URL.createObjectURL(image.origin);
        img.onload = () => {
            setImgEl(img);
        };
        img.onerror = () => {
            setImgEl(undefined);
        };
    }, [image.origin]);

    const handleSave = useEvent(async () => {
        if (!cropperRef.current) {
            return;
        }

        updateCheckpoint({
            images: images.map((i) => {
                if (i.id !== image.id) {
                    return i;
                }

                return {
                    ...image,
                    small: undefined,
                };
            }),
        });

        cropperRef.current.cropper.getCroppedCanvas().toBlob(async (blob) => {
            if (!blob) {
                return;
            }

            const resized = await resizer.resize([new File([blob], image.origin.name, { type: image.origin.type })]);

            updateCheckpoint({
                images: images.map((i) => {
                    if (i.id !== image.id) {
                        return i;
                    }

                    return {
                        ...image,
                        small: resized?.[0],
                    };
                }),
            });
        });

        onClose();
    });

    const handleRemove = useEvent(() => {
        updateCheckpoint({
            images: images.filter((i) => i.id !== image.id),
        });
    });

    // 64 - отступы по бокам, 224 - отступы + кнопки снизу
    const cropperStyle = useMemo(() => ({ width: window.innerWidth - 64, height: window.innerHeight - 224 }), []);

    return (
        <ModalOverlay opened onClose={onClose}>
            {imgEl && (
                <div
                    className="w-full h-full flex flex-col justify-center items-center gap-16"
                    onClick={(event) => event.stopPropagation()}
                >
                    <Cropper
                        src={imgEl.src}
                        style={cropperStyle}
                        aspectRatio={1}
                        viewMode={2}
                        dragMode="move"
                        background={false}
                        modal={false}
                        autoCropArea={1}
                        ref={cropperRef}
                    />
                    <Button theme="orange" onClick={handleSave}>
                        Сохранить
                    </Button>
                    <Button theme="red" onClick={handleRemove}>
                        Удалить
                    </Button>
                </div>
            )}
        </ModalOverlay>
    );
};
