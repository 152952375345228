import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SwipeCallback, useSwipeable, UP, DOWN, LEFT, RIGHT } from 'react-swipeable';

const BAD_DIRECTIONS = [UP, DOWN];
const FLICK_THRESHOLD = 0.4;
const DURATION = 450;

type Props = {
    images: File[];
    currentImage: number;
    onExit(): void;
    gallery: React.RefObject<HTMLDivElement>;
};

const FullScreenGalleryFC: FC<Props> = ({ images, currentImage, onExit, gallery }) => {
    const [curSlide, setCurSlide] = useState(currentImage);
    const [slidesOffset, setSlidesOffset] = useState(currentImage * -100);
    const [offsetDuration, setOffsetDuration] = useState(0);

    const imagesUrl = useMemo(
        () => images.map((image) => ({ name: image.name, url: URL.createObjectURL(image) })),
        [images]
    );

    useEffect(() => {
        setCurSlide(currentImage);
        setSlidesOffset(currentImage * -100);
    }, [currentImage]);

    useEffect(() => {
        const listener = () => {
            !document.fullscreenElement && onExit();
        };

        document.addEventListener('fullscreenchange', listener);

        return () => document.removeEventListener('fullscreenchange', listener);
    }, [onExit]);

    const handleSwiping: SwipeCallback = useCallback(
        ({ event, absX, dir }) => {
            event.preventDefault();
            // console.log(dir, absX);

            if (BAD_DIRECTIONS.includes(dir) || !gallery.current) {
                return;
            }

            const galleryWidth = gallery.current.getBoundingClientRect().width;

            const offsetDiff = (dir === LEFT ? -1 : 1) * Math.min((absX / galleryWidth) * 100, 100);

            setOffsetDuration(0);
            setSlidesOffset(curSlide * -100 + offsetDiff);
        },
        [curSlide]
    );

    const handleSwiped: SwipeCallback = useCallback(
        ({ event, absX, dir, velocity }) => {
            event.preventDefault();
            console.log(dir, absX, velocity);

            if (BAD_DIRECTIONS.includes(dir) || !gallery.current) {
                return;
            }

            const galleryWidth = gallery.current.getBoundingClientRect().width;

            const offsetDiff = Math.min((absX / galleryWidth) * 100, 100);
            const shouldSwipeNext =
                (offsetDiff >= 50 || velocity >= FLICK_THRESHOLD) &&
                ((dir === RIGHT && curSlide > 0) || (dir === LEFT && curSlide < images.length - 1));

            setOffsetDuration(DURATION);

            if (!shouldSwipeNext) {
                return setSlidesOffset(curSlide * -100);
            }

            const newIndex = curSlide + (dir === LEFT ? 1 : -1);

            setCurSlide(newIndex);
            setSlidesOffset(newIndex * -100);
        },
        [curSlide, images.length]
    );

    const handlers = useSwipeable({
        onSwiping: handleSwiping,
        onSwiped: handleSwiped,
    });

    return (
        <div
            className="w-full h-full flex items-center"
            style={{ transform: `translateX(${slidesOffset}%)`, transition: `all ${offsetDuration}ms ease-out` }}
            {...handlers}
        >
            {imagesUrl.map((image) => (
                <img key={image.name} src={image.url} className="object-contain" />
            ))}
        </div>
    );
};

export const FullScreenGallery = React.memo(FullScreenGalleryFC);
