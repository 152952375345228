import type { FC } from 'react';
import React from 'react';
import { useCheckpointContext } from '../CheckpointContextProvider';
import edit from '../static/edit.png';

export const CheckpointEditBtn: FC = () => {
    const { onChangeEdit } = useCheckpointContext();

    return <img src={edit} onClick={onChangeEdit} width={32} height={32} className="inline-block" />;
};
