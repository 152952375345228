import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { YMapListener } from 'ymap3-components';
import { MapEventUpdateHandler } from '@yandex/ymaps3-types/imperative/YMapListener';
import { useDebouncedCallback } from 'use-debounce';
import { DateObject } from 'react-multi-date-picker';
import { toast } from 'react-toastify';
import { geocode } from '../../services/geocode';
import { PLACEMARK_COLORS } from '../CheckpointSheet/components/CheckpointMarker';
import { Coords } from '../../types/types';
import { useEvent } from '../../hooks/useEvent';
import { useMap } from '../../context/MapContext';
import { TgPoint } from '../MapElements/TgPoint/TgPoint';
import { EntrySheet } from './EntrySheet';
import { CheckpointSheet } from '../CheckpointSheet/CheckpointSheet';
import { CheckpointSheetWrapper } from '../CheckpointSheet/CheckpointSheetWrapper';
import { Checkpoint } from '../../db';
import styles from './EntryPlacemarkFC.module.css';
import placemark from '../CheckpointSheet/static/placemark.png';

const EntryPlacemarkFC: FC = () => {
    const map = useMap();

    const [entryHidden, setEntryHidden] = useState(false);

    const [opened, setOpened] = useState(false);
    const [coords, setCoords] = useState<Coords>();
    const [city, setCity] = useState<string>();

    const debounceEntryShow = useDebouncedCallback(() => setEntryHidden(false), 300);

    const [opened2nd, setOpened2nd] = useState(false);
    const [checkpoint, setCheckpoint] = useState<Checkpoint>({
        lon: coords?.[0] || map.center[0],
        lat: coords?.[1] || map.center[1],
        color: PLACEMARK_COLORS[12],
        location: city || '',
        date: [new DateObject(Date.now())],
        images: [],
    });

    useEffect(
        () =>
            setCheckpoint({
                lon: coords?.[0] || map.center[0],
                lat: coords?.[1] || map.center[1],
                color: PLACEMARK_COLORS[12],
                location: city || '',
                date: [new DateObject(Date.now())],
                images: [],
            }),
        [city, coords]
    );

    const handleMapUpdate = useEvent<MapEventUpdateHandler>(({ location: { center }, mapInAction }) => {
        if (!opened) {
            return mapInAction ? setEntryHidden(true) : debounceEntryShow();
        }

        setCoords([center[0], center[1]]);

        if (mapInAction) {
            return setCity(undefined);
        }

        geocode([center[0], center[1]]).then((res) => setCity(res?.GeoObject.name || 'Неизвестно'));
    });

    const handleMapClick = useEvent(() => {
        if (!opened) {
            return;
        }

        setOpened(false);
        setCoords(undefined);
    });

    const handleEntryBtnClick = useEvent(() => {
        const curCoords: Coords = [map.center[0], map.center[1]];

        setOpened(true);
        requestAnimationFrame(() => setCoords(curCoords));

        geocode(curCoords).then((res) => setCity(res?.GeoObject.name || 'Неизвестно'));
    });

    const handleSuccessBtnClick = useEvent(() => {
        if (!city || !coords) {
            return;
        }

        setOpened(false);
        setCoords(undefined);
        setOpened2nd(true);
    });

    const handle2ndClose = useEvent(() => {
        setOpened2nd(false);
    });

    const handleSave = () => {
        setOpened2nd(false);

        toast.success('Метка добавлена!', { autoClose: 1500 });
    };

    const isEntryMarkHidden = entryHidden || opened || opened2nd;

    return (
        <>
            <div
                className={cx(
                    styles.entry,
                    'fixed right-12 w-40 h-40 rounded-full bg-white flex justify-center items-center',
                    {
                        '-bottom-48': isEntryMarkHidden,
                        'bottom-48': !isEntryMarkHidden,
                    }
                )}
                onClick={handleEntryBtnClick}
            >
                <img src={placemark} className="w-20 h-20" />
            </div>
            <YMapListener onUpdate={handleMapUpdate} onClick={handleMapClick} />
            {coords && <TgPoint color={PLACEMARK_COLORS[12]} coords={coords} />}
            <EntrySheet
                opened={opened}
                loading={!city}
                title={city || ''}
                onClose={handleMapClick}
                onSuccess={handleSuccessBtnClick}
            />
            <CheckpointSheetWrapper checkpoint={checkpoint} isEditing>
                <CheckpointSheet open={opened2nd} onClose={handle2ndClose} onSave={handleSave} />
            </CheckpointSheetWrapper>
        </>
    );
};

export const EntryPlacemark = React.memo(EntryPlacemarkFC);
