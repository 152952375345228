import { YANDEX_API_KEY } from './constants';

type GeocodeResponse = {
    response: {
        GeoObjectCollection: {
            featureMember: [
                {
                    GeoObject: {
                        name: string;
                    };
                },
            ];
        };
    };
};

export const geocode = (coords: [number, number]) => {
    const reqCoords = coords.join(',');

    return fetch(
        `https://geocode-maps.yandex.ru/1.x/?apikey=${YANDEX_API_KEY}&geocode=${reqCoords}&kind=locality&format=json`
    )
        .then((res) => {
            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return res.json();
        })
        .then((res: GeocodeResponse) => {
            console.log(res);

            return res.response.GeoObjectCollection.featureMember[0];
        });
};
