import type { FC } from 'react';
import React from 'react';
import { createPortal } from 'react-dom';

type Props = {
    opened: boolean;
    onClose(): void;
};

export const ModalOverlay: FC<React.PropsWithChildren<Props>> = ({ opened, onClose, children }) => {
    if (typeof document === 'undefined') {
        return null;
    }

    if (!opened) {
        return null;
    }

    return createPortal(
        <div
            className="fixed top-0 left-0 w-full h-full px-32 py-40 bg-black-90 z-[2000] flex items-center"
            onClick={onClose}
        >
            {children}
        </div>,
        document.body
    );
};
