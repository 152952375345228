import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { FullScreenGallery } from './FullScreenGallery';
import { PanelItem } from './PanelItem';
import { useEvent } from '../../../../hooks/useEvent';
import { useCheckpointContext } from '../../CheckpointContextProvider';
import plus from '../../static/plus.png';

const imageProcessingWorker = createWorkerFactory(() => import('../resizer'));

const PhotoLoadPanelFC: FC = () => {
    const {
        checkpoint: { images },
        isEditing,
        updateCheckpoint,
    } = useCheckpointContext();

    const galleryRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [fullScreen, setFullScreen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const resizer = useWorker(imageProcessingWorker);

    const handlePlusClick = () => inputRef.current?.click();

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const newImages = event.target.files;

        if (!newImages) {
            return;
        }

        const filterImages: File[] = Array.prototype.slice.call(newImages).filter((newImage: File) => {
            if (images.find((image) => image.id === newImage.name)) {
                toast.warn(`Вы уже добавляли файл ${newImage.name}`, { autoClose: 1500 });

                return false;
            }

            return true;
        });

        updateCheckpoint({
            images: [
                ...images,
                ...filterImages.map((file) => ({
                    id: file.name,
                    origin: file,
                })),
            ],
        });

        const resizedImages = await resizer.resize(filterImages);

        updateCheckpoint({
            images: [
                ...images,
                ...filterImages.map((image, index) => ({
                    id: image.name,
                    origin: image,
                    small: resizedImages?.[index],
                })),
            ],
        });

        // @ts-expect-error not go to fix it
        event.target.value = null;
    };

    const handleFullScreen = useEvent((index: number) => {
        if (!galleryRef.current) {
            return;
        }

        setCurrentImage(index);

        try {
            galleryRef.current.requestFullscreen().then(() => {
                setFullScreen(true);
            });
        } catch (_) {
            toast.warn('Ваше устройство не поддерживает полноэкранный режим :(');
        }
    });

    const handleExitFullScreen = useCallback(() => setFullScreen(false), []);

    const hasImages = images.length > 0;

    return (
        <>
            <div className={hasImages ? 'grid grid-cols-3 gap-2' : 'w-full'}>
                {images.map((image, index) => (
                    <PanelItem image={image} onClick={handleFullScreen} index={index} key={image.id} />
                ))}
                {isEditing && (
                    <div
                        className={cx(
                            'basis-1/3 bg-white rounded-m overflow-hidden border border-solid border-light-gray flex justify-center items-center',
                            hasImages ? 'aspect-square' : 'aspect-3/1'
                        )}
                        onClick={handlePlusClick}
                    >
                        <img src={plus} className="inline-block w-64 h-64" />
                        <input className="hidden" type="file" onChange={handleChange} ref={inputRef} multiple />
                    </div>
                )}
            </div>
            <div className={fullScreen ? 'block' : 'hidden'} ref={galleryRef}>
                {fullScreen && (
                    <FullScreenGallery
                        images={images.map(({ origin }) => origin)}
                        currentImage={currentImage}
                        onExit={handleExitFullScreen}
                        gallery={galleryRef}
                    />
                )}
            </div>
        </>
    );
};

export const PhotoLoadPanel = React.memo(PhotoLoadPanelFC);
