import type { FC } from 'react';
import React from 'react';
import { Point } from '../../../types/types';
import { CheckpointSheetWrapper } from '../../CheckpointSheet/CheckpointSheetWrapper';
import { SavedPoint } from './SavedPoint';

type Props = {
    point: Point;
};

export const SavedPointWrapper: FC<Props> = ({ point }) => (
    <CheckpointSheetWrapper checkpoint={point.properties}>
        <SavedPoint />
    </CheckpointSheetWrapper>
);
