import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { CheckpointContext, CheckpointContextProvider } from './CheckpointContextProvider';

type CheckpointSheetProps = Pick<CheckpointContext, 'checkpoint'> & {
    isEditing?: boolean;
    children: React.ReactNode;
};

export const CheckpointSheetWrapper: FC<CheckpointSheetProps> = (props) => {
    const [isEditing, setIsEditing] = useState(props.isEditing || false);
    const [checkpoint, setCheckpoint] = useState(props.checkpoint);

    useEffect(() => setCheckpoint(props.checkpoint), [props.checkpoint]);

    const context = useMemo<CheckpointContext>(
        () => ({
            checkpoint,
            isEditing: props.isEditing || isEditing,
            onChangeEdit: () => setIsEditing((prev) => !prev),
            onCancelEdit: () => {
                setIsEditing(false);
                setCheckpoint(props.checkpoint);
            },
            updateCheckpoint: (value) => setCheckpoint((prev) => ({ ...prev, ...value })),
        }),
        [checkpoint, isEditing]
    );

    return <CheckpointContextProvider value={context}>{props.children}</CheckpointContextProvider>;
};
