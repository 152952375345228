import type { FC } from 'react';
import React from 'react';
import { BottomSheet } from 'components/Base/BottomSheet/BottomSheet';
import { MarkerSVG } from '../CheckpointSheet/components/MarkerSVG';
import { PLACEMARK_COLORS } from '../CheckpointSheet/components/CheckpointMarker';
import { Skeleton } from '../Base/Skeleton';
import { Button } from '../Base/Button';

type Props = {
    opened: boolean;
    loading: boolean;
    title: string;
    onClose: () => void;
    onSuccess: () => void;
};

export const EntrySheet: FC<Props> = ({ opened, loading, title, onClose, onSuccess }) => (
    <BottomSheet opened={opened} onClose={onClose} detent="content-height" noBackdrop>
        <div className="px-16 mb-16">
            <div className="flex">
                <div className="h-24 w-24">
                    <MarkerSVG color={PLACEMARK_COLORS[12]} />
                </div>
                <span className="first-letter:uppercase font-medium ml-4">
                    {loading ? <Skeleton height={24} width={110} /> : title}
                </span>
            </div>
            {!loading && (
                <div className="mt-16 mb-40">
                    <Button theme="green" onClick={onSuccess}>
                        Далее
                    </Button>
                </div>
            )}
        </div>
    </BottomSheet>
);
