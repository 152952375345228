import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import transition from 'react-element-popper/animations/transition';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import { formatSelectedDate, MONTHS, WEEK_DAYS } from '../../../utils/dateFormat';
import './CheckpointDate.css';
import { useCheckpointContext } from '../CheckpointContextProvider';
import calendarPng from '../static/calendar.png';

const CheckpointDateFC: FC = () => {
    const {
        checkpoint: { date },
        isEditing,
        updateCheckpoint,
    } = useCheckpointContext();

    const [enableAnimation, setEnableAnimation] = useState(true);

    const handleDateOpen = useCallback(() => {
        setTimeout(() => setEnableAnimation(false), 300);
    }, []);
    const handleDateClose = useCallback(() => setEnableAnimation(true), []);
    const handleDateChange = useCallback((newDate: null | DateObject | DateObject[]) => {
        if (!Array.isArray(newDate) || !newDate.length) {
            return;
        }

        if (newDate.length === 1) {
            return updateCheckpoint({ date: [newDate[0]!] });
        }

        return updateCheckpoint({ date: [newDate[0]!, newDate[1]!] });
    }, []);

    return (
        <DatePicker
            className="calendar"
            value={date}
            onChange={handleDateChange}
            range
            weekStartDayIndex={1}
            weekDays={WEEK_DAYS}
            months={MONTHS}
            arrow={false}
            calendarPosition="bottom-start"
            fixMainPosition
            fixRelativePosition
            offsetY={4}
            animations={enableAnimation ? [transition({ duration: 300 })] : undefined}
            render={(_, openCalendar) => (
                <div className="flex" onClick={isEditing ? openCalendar : undefined}>
                    <img src={calendarPng} width={32} height={32} className="inline-block" />
                    <span className="ml-4 text-gray text-l leading-8">{formatSelectedDate(date)}</span>
                </div>
            )}
            onOpen={handleDateOpen}
            onClose={handleDateClose}
        />
    );
};

export const CheckpointDate = React.memo(CheckpointDateFC);
